import SectionHeading from '../../../components/SectionHeading'
import SingleFAQArea from './SingleFAQArea'
import IcoCounter from './IcoCounter'
import IcoDocs from './IcoDocs'
import img from '../../../assets/img/bg-img/kron6.png'
const SecFAQ_Timeline = ({FQAInfo , DocElementTitle}) => {

  return (
    <section id='whoarewe' className="faq-timeline-area section-padding-100">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-7 col-md-12 mb-5">
                        <br/>
                    <br/>
                    <SectionHeading
                        title='WHY KRON FINANCIAL?'
                        text='Unlock an unparalleled investment experience in the Stock Exchange with the advantages Kron Financial brings to the table. Swiftly execute your trading tactics, gain instant access to comprehensive financial information and global market insights, tap into investment possibilities spanning the globe, and receive exceptional customer-focused support from our knowledgeable experts.'
                    />

                    {/* <div className="dream-faq-area">
                        <div className="panel-group" id="accordionFourLeft" data-aos="fade-up">
                            {FQAInfo && FQAInfo.map((item , key) => (
                                <>
                                    <SingleFAQArea key={key} text={item.text} ID={item.ID} />
                                </>
                            ))}
                        </div>
                    </div> */}
                </div>

                <div className="col-12 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-10 offset-sm-1 mt-5">

                    {/* <IcoCounter /> */}

                    {/* <IcoDocs data={DocElementTitle} /> */}<br/><br/>
                   <img style={{borderRadius:'25px'}} src={img} />
                </div>
            </div>
        </div>
    </section>
  );
}

export default SecFAQ_Timeline;