const ServiceBlock = ({classIco , title,text}) => {

  return (
    <div className="services-block-four">
        <div className="inner-box">
            <div className="icon-box">
                <span className={classIco}></span>
            </div><br/>
            <div className="text">{title}</div>
            <div className="text"> {text}</div>
        </div>
    </div>
  );
}

export default ServiceBlock;