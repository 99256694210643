import { useEffect, useState } from 'react'

import  Logo  from '../../assets/img/kronLogo.png';

import { Addshrink, addActiveClass, OpenMenu, moveSmooth } from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
    Addshrink()
  }, [])

  useEffect(() => {
    OpenMenu()
  }, [])

  useEffect(() => {
    moveSmooth()
  }, [])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="#"><img style={{width:"170px"}} src={Logo} alt="logo" /></a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                  <span />
                  <span />
                  <span />
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                    <span className="top" />
                    <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <li><a onClick={moveSmooth} href="#">Home</a></li>
                    <li><a onClick={moveSmooth} href="#about">About Us</a></li>
                    <li><a onClick={moveSmooth} href="#services">Services</a></li>
                    <li><a onClick={moveSmooth} href="#whoarewe">Why Kron Financial?</a></li>
                    {/* <li><a onClick={moveSmooth} href="#videoArea">Spread Oranları</a></li> */}
                    <li><a onClick={moveSmooth} href="#appSection">Trading Platform</a></li>
                    <li><a onClick={moveSmooth} href="#contact">Contact</a></li>

                  </ul>
                  {/* <a href="#" className="btn login-btn ml-50">Giriş Yap</a> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;