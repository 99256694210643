function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img src={img} width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">Discover a new ways to enjoy your World!</span>
              </div>
          </div>
          <h1>Kron Financial</h1>
          <p>
          Kron Financial, boasts extensive experience in the realm of capital markets, positioning itself as a prominent player in the global financial landscape. With a strong focus on general stock brokerage services, we excel particularly in leveraged trading and futures transactions.
          </p>
          <div className="dream-btn-group">
              {/* <a href="#" className="btn dream-btn mr-3">Learn More</a> */}
              <a href="#services" className="btn dream-btn">Details</a>
          </div>
      </div>
  )
}

export default SecWelcomeContent