import SectionHeading from '../../../components/SectionHeading'
import ServiceBlock from './ServiceBlock'

const SecOurFeatures = ({data , imgPhone , Rings}) => {

  return (
    <section id='appSection' className="features section-padding-100">

        <SectionHeading
            title='Kron Financial'
            text="Favored among expert investors, Sirix's exceptional technical and fundamental analysis tools, coupled with its enriched trading functions, shine a spotlight on its unique attribute: the capacity to unite diverse markets within a singular platform. This empowers efficient transactions within financial markets using a comprehensive, multifunctional trading platform."
        />
        <div className="container-fluid">
            <div className="row">
                <div className="services-column col-lg-5 col-lg-offset-1 col-md-10 offset-md-1 col-xs-10 offset-xs-1">
                    <h3 style={{color:"white"}}>Minimum System Requirements</h3>
                    <br/>
                    {data && data.map((item , key) => (
                        <ServiceBlock
                            key={key}
                            classIco={item.classIco}
                            title={item.title}
                            text={item.text}
                        />
                    ))}
                    
                </div>
                <div className="service-img-wrapper col-lg-6 col-md-12 col-sm-12">
                    <div className="image-box">
                        <img src={imgPhone} style={{borderRadius:"50px"}} className="center-block img-responsive phone-img" alt="" />
                        <img src={Rings} className="center-block img-responsive rings " alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default SecOurFeatures;