import {
    HomeDemo2IcoPlatforms1,
    HomeDemo2IcoPlatforms2,
    HomeDemo2IcoPlatforms3,
    HomeDemo2IcoPlatforms4,
    HomeDemo2IcoPlatforms5,
    HomeDemo2IcoPlatforms6,

    HomeDemo2Services1,
    HomeDemo2Services2,
    HomeDemo2Services3,
    HomeDemo2Services4,
    HomeDemo2Services5,
    HomeDemo2Services6,

    HomeDemo2TokenIcon1,

    HomeDemo2TeamImg1,
    HomeDemo2TeamImg2,
    HomeDemo2TeamImg3,
    HomeDemo2TeamImg4,
    
    HomeDemo2Partners1,
    HomeDemo2Partners2,
    HomeDemo2Partners3,
    HomeDemo2Partners4,
    HomeDemo2Partners5,
    HomeDemo2Partners6,
    HomeDemo2Partners7,
    HomeDemo2Partners8,
    HomeDemo2Partners9,
    HomeDemo2Partners10,
    HomeDemo2Partners11,
    HomeDemo2Partners12,

    HomeDemo2RoadmapIcons1,
    HomeDemo2RoadmapIcons2,
    HomeDemo2RoadmapIcons3,
    HomeDemo2RoadmapIcons4,
    HomeDemo2RoadmapIcons5,
    HomeDemo2RoadmapIcons6,
    HomeDemo2RoadmapIcons7,
    HomeDemo2RoadmapIcons8,
    HomeDemo2RoadmapIcons9,

    HomeDemo2BlogImg1,
    HomeDemo2BlogImg2,
    HomeDemo2BlogImg3

} from '../../utils/allImgs'

export const VerticalSocial = [
    {nameIco:'fa fa-telegram'},
    {nameIco:'fa fa-medium'},
    {nameIco:'fa fa-facebook'},
    {nameIco:'fa fa-twitter'},
    {nameIco:'fa fa-github'},
    {nameIco:'fa fa-instagram'},
    {nameIco:'fa fa-linkedin'}
]

export const SingleCoolFact = [
    {
        img:HomeDemo2IcoPlatforms1,
        ico_check:true
    },
    {
        img:HomeDemo2IcoPlatforms2,
        ico_check:false
    },
    {
        img:HomeDemo2IcoPlatforms3,
        ico_check:true
    },
    {
        img:HomeDemo2IcoPlatforms4,
        ico_check:true
    },
    {
        img:HomeDemo2IcoPlatforms5,
        ico_check:false
    },
    {
        img:HomeDemo2IcoPlatforms6,
        ico_check:true
    }
]

export const service_single_content = [
    {
        img:HomeDemo2Services2,
        title:'Financial Support',
        text:'The services provided by Kron Financial ensure that investments are shielded from risks not linked to business operations, and simultaneously aid investors in securing funding from various sources with enhanced financial terms.'
    },
    {
        img:HomeDemo2Services4,
        title:'Investment Program',
        text:'Geared towards simplifying investments in finance, agribusiness, manufacturing, and services sectors of small and medium-sized enterprises, this initiative presents an efficient approval procedure, enabling investors to swiftly secure coverage from Kron Financial.'
    },
    {
        img:HomeDemo2Services5,
        title:'Financial Education',
        text:'Step into the realm of investments by attending seminars that carry a significant impact; our training workshops are designed to make a real difference.'
    }
    // ,
    // {
    //     img:HomeDemo2Services4,
    //     title:'Exchange Order Management'
    // },
    // {
    //     img:HomeDemo2Services5,
    //     title:'Module of Price Notification'
    // },
    // {
    //     img:HomeDemo2Services6,
    //     title:'Crypto Trading Platform'
    // }

]

export const timelineInfo = [
    {
        left:"10%",
        ClassName:"data_cir cir_0",
        date:"Q2 2017",
        IsSpan:true,
        TextSpan:"Emergence and design of the idea",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons1

    },
    {
        left:"20%",
        ClassName:"data_cir cir_1",
        date:"Q3 2017",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:true,
        TextFourLi1:"built our team",
        TextFourLi2:"company establishment",
        TextFourLi3:"legal issues setting",
        TextFourLi4:" concept development",
        img:HomeDemo2RoadmapIcons2

    },
    {
        left:"30%",
        ClassName:"data_cir cir_2",
        date:"Q4 2017",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:'start development of the ealier version and crypto wallet version',
        TextTowLi2:'negotiations on partnerships',
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons3

    },
    {
        left:"40%",
        ClassName:"data_cir cir_3",
        date:"Q1 2018",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"website launch",
        TextThreeLi2:"White Paper published",
        TextThreeLi3:"Private and Pre-sale start",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons4

    },
    {
        left:"50%",
        ClassName:"data_cir cir_4 red_c",
        date:"Q2 2018",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Crowdsale",
        TextThreeLi2:"marketing campaign",
        TextThreeLi3:"listing to the major exchanges",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons5

    },
    {
        left:"60%",
        ClassName:"data_cir cir_5 next",
        date:"Q3 2018",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Crypto Wallet version release",
        TextThreeLi2:"Testnet launch",
        TextThreeLi3:" Platform ealier version development",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons6

    },
    {
        left:"70%",
        ClassName:"data_cir cir_6 next",
        date:"Q4 2018",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Mobile App",
        TextThreeLi2:"Explorer",
        TextThreeLi3:"new partnerships and new marketplace support",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons7

    },
    {
        left:"80%",
        ClassName:"data_cir cir_7 next",
        date:"Q1 2019",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Mainnet launch",
        TextThreeLi2:"Wallet beta-version release",
        TextThreeLi3:"Masternodes implementation",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons8

    },
    {
        left:"90%",
        ClassName:"data_cir cir_8 next",
        date:"Q2 2019",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:"Platform integration to electronic marketplaces",
        TextTowLi2:"Zero Fee",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons9

    }
]

export const ServiceBlock = [
    {
        classIco:"icon ti-mobile",
        title:"Microsoft Windows 11/10",
        text:"2.0 GHz or faster CPU"
    }
    ,
    {
        classIco:"icon ti-widget",
        title:"1GB RAM",
        text:"Screen resolution of 1024x768 pixels or more"
    },
    {
        classIco:"icon ti-settings",
        title:"An internet connection of 128 kbps or faster",
        text:""
    }
]

export const SocialListIco = [
    {icoName:"fa fa-facebook-square"},
    {icoName:"fa fa-twitter-square"},
    {icoName:"fa fa-github-square"},
    {icoName:"fa fa-instagram"},
    {icoName:"fa fa-medium"}
]

export const FQAInfo = [
    {
        text:'What are the objectives of this token?',
        ID:'Q1'
    },
    {
        text:'What is Token Sale and pre-sale?',
        ID:'Q2'
    },
    {
        text:'What is the pre-sale start date?',
        ID:'Q3'
    },
    {
        text:'how may I take part in pre-sale?',
        ID:'Q4'
    },
]

export const DocElementTitle = [
    {
        title:'WhitePaper'
    },
    {
        title:'OnePager'
    },
    {
        title:'Rating Review'
    },
    {
        title:'Marketing Plan'
    },
]

export const TokenText = [
    {
        text:'Lorem ipsum dolor sit amet, conse ctetur elit',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'Sed quis accumsan nisi Ut ut felis',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'felis congue nisl hendrerit commodo',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'arch nemo sequi rem saepe ad quasi ullam.',
        img:HomeDemo2TokenIcon1
    },
]

export const MissionData = [
    {
        icoName:"ti-shine",
        title:"Quality"
    },
    {
        icoName:"ti-ruler-pencil",
        title:"Creativity"
    },
    {
        icoName:"ti-heart",
        title:"Reliability"
    },
]

export const TeamMember = [
    {
        img:HomeDemo2TeamImg1,
        title:'Sunny khan',
        text:'Executive Officer'
    },
    {
        img:HomeDemo2TeamImg2,
        title:'Ajoy Das',
        text:'Business Development'
    },
    {
        img:HomeDemo2TeamImg3,
        title:'Afroza Mou',
        text:'UX/UI Designer'
    },
    {
        img:HomeDemo2TeamImg4,
        title:'Lim Sarah',
        text:'Head of Marketing'
    },
    {
        img:HomeDemo2TeamImg1,
        title:'Sunny khan',
        text:'Executive Officer'
    },
    {
        img:HomeDemo2TeamImg2,
        title:'Ajoy Das',
        text:'Business Development'
    },
    {
        img:HomeDemo2TeamImg3,
        title:'Afroza Mou',
        text:'UX/UI Designer'
    },
    {
        img:HomeDemo2TeamImg4,
        title:'Lim Sarah',
        text:'Head of Marketing'
    },
]

export const PartnersData = [
    {img:HomeDemo2Partners1},
    {img:HomeDemo2Partners2},
    {img:HomeDemo2Partners3},
    {img:HomeDemo2Partners4},
    {img:HomeDemo2Partners5},
    {img:HomeDemo2Partners6},
    {img:HomeDemo2Partners7},
    {img:HomeDemo2Partners8},
    {img:HomeDemo2Partners9},
    {img:HomeDemo2Partners10},
    {img:HomeDemo2Partners11},
    {img:HomeDemo2Partners12}
]

export const BlogPost = [
    {img:HomeDemo2BlogImg1},
    {img:HomeDemo2BlogImg2},
    {img:HomeDemo2BlogImg3}
]
