const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots" data-aos="fade-up">
                {Array(7).fill().map((key) => (
                		<Span />
                	))}
            </div>
            <h4 data-aos="fade-up">About Us</h4>
            <p data-aos="fade-up">Since its inception, Kron Financial has achieved remarkable global expansion, bringing both individual and corporate clientele across over 170 nations—and our growth trajectory continues. As Kron Financial, we have ventured into numerous countries, driven by our commitment to deliver exceptional and dependable services to our esteemed patrons.</p>
            <p data-aos="fade-up">Our documentary credits, facilitated by Kron Financial, hold universal recognition and can be initiated through various methods. You can easily secure a letter of credit from us, subject to a nominal fee. Importantly, your personal credit rating or relationship with your bank doesn't influence this process. These arrangements don't require you to lock in your funds, nor do we assess your company's financials...





</p>
            <a className="btn dream-btn mt-30" href="#services">View Services</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;